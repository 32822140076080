<template>
 <div>
    <b-card>
    <b-tabs>
      <b-tab active title="English">
        <b-card-text>
          <validation-observer ref="amenityForm">
            <b-form>
              <b-row>
                <b-col md="6" class="align-self-center">
                  <b-form-group class="mb-0">
                    <validation-provider
                      #default="{ errors }"
                      name="Amenity Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="amenity.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Amenity Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2" class="align-self-center">
                  <ComponentMediaSelector
                    :params="{ lang: null }"
                    :isMultiple="false"
                    @mediaSelected="handleMediaSelect"
                  />
                </b-col>
                <b-col v-if="amenity.icon" md="3">
                  <b-img
                    v-bind="mainProps"
                    rounded="circle"
                    alt="Circle image"
                    :src="amenity.icon"
                  />
                <a @click="amenity.icon = ''">Remove Thumbnail</a>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-tab>
      <b-tab title="Hindi">
        <b-card-text>
          <b-form>
            <b-row>
              <b-col md="6" class="align-self-center">
                <b-form-group class="mb-0">
                  <b-form-input
                    v-model="amenity.translations.hi.name"
                    placeholder="Amenity Name (Hindi)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" class="align-self-center">
                <ComponentMediaSelector
                  :isMultiple="false"
                  :params="{ lang: 'hi' }"
                  @mediaSelected="handleMediaSelect"
                />
              </b-col>
              <b-col v-if="amenity.translations.hi.icon" md="3">
                <b-img
                  v-bind="mainProps"
                  rounded="circle"
                  alt="Circle image"
                  :src="amenity.translations.hi.icon"
                />
                <a @click="amenity.translations.hi.icon = ''">Remove Thumbnail</a>
              </b-col>
            </b-row>
          </b-form>
        </b-card-text>
      </b-tab>
      <b-tab title="Marathi">
        <b-card-text>
          <b-form>
            <b-row>
              <b-col md="6" class="align-self-center">
                <b-form-group class="mb-0">
                  <b-form-input
                    v-model="amenity.translations.mr.name"
                    placeholder="Amenity Name (Marathi)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" class="align-self-center">
                <ComponentMediaSelector
                  :isMultiple="false"
                  :params="{ lang: 'mr' }"
                  @mediaSelected="handleMediaSelect"
                />
              </b-col>
              <b-col v-if="amenity.translations.mr.icon" md="3">
                <b-img
                  v-bind="mainProps"
                  rounded="circle"
                  alt="Circle image"
                  :src="amenity.translations.mr.icon"
                />
                <a @click="amenity.translations.mr.icon = ''">Remove Thumbnail</a>
              </b-col>
            </b-row>
          </b-form>
        </b-card-text>
      </b-tab>
    </b-tabs>
  
  </b-card>
  <b-card>
      
      <b-row>
        <b-col>
          <b-button @click="createAmenity" size="lg" block variant="primary">Submit</b-button>
        </b-col>
      </b-row>
  </b-card>
 </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BTabs,
  BTab,
  BCardText,
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BImg,
} from "bootstrap-vue";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BCardText,
    BTab,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    ComponentMediaSelector,
    BImg,
  },
  data() {
    return {
      required,
      mainProps: {
        width: 75,
        height: 75,
      },
      amenity: {
        name: "",
        icon: "",
        translations: {
          hi: {
            name: "",
        icon: "",

          },
          mr: {
            name: "",
        icon: "",
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    handleMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if (mediaObject.params.lang) {
          this.amenity.translations[mediaObject.params.lang].icon =
            mediaObject.media.src;
        } else {
          this.amenity.icon = mediaObject.media.src;
        }
      }
    },
    createAmenity() {
      this.$refs.amenityForm.validate().then((success) => {
        if (success) {
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/hotel/amenity/create`,
              this.amenity,
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              this.makeToast(
                "success",
                "Success",
                `${response.data.name} has been created successfully!`
              );
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast(
                "danger",
                "Error",
                "Error while creating amenity!"
              );
            });
        }
      });
    },
  },
};
</script>
 